import router from '@/router';

export default class InspectionViewModel {
  constructor() {
    this.calendarScheduleRangeData = {
      targetMonth: '2022-07', // 월간 - 기준월 YYYY-MM
      searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
      limit: { minMonth:'', maxMonth:'', isMaxMonthToday:false }, // 월간 - 기준 최소시작일
      isSchedule: true,
      isShowFootFix: true,
    };
    this.scheduleRangeDataList = [];
    this.scheduleItemModel = {
      start_at:'',
      end_at:'',
    }
    this.isSchedulePopup = false;
    this.isSchedulePopupModify = false;
  }
  init(){
    this.getData();
  }
  onAddSchedule(date){
    this.addScheduleDate = date;
    this.isSchedulePopup = true;
  }
  onClickCloseSchedulePopup(){
    this.isSchedulePopup = false;
  }
  onClickDeleteSchedule(){
    this.isSchedulePopup = false;
  }
  getData(){
    this.scheduleRangeDataList = [
      { id:1, title:'정기정검', type:'APP', startAt:'2022-07-12T23:00:00', endAt:'2022-07-13T01:00:00', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
      { id:2, title:'정기정검', type:'APP', startAt:'2022-07-11T23:00:00', endAt:'2022-07-12T23:00:00', createdAt:"2022-01-24T12:05:01", updatedAt:"2022-01-24T12:05:01", writer:{ id: 8, name:'유보현', email:'kerry@lemontree.ai', image:'https://lt-admin-public.s3.ap-northeast-2.amazonaws.com/images/admins/profile_kerry.png' } },
    ]
  }
}