<template>
  <PageWithLayout>
    <Calendar
      :calendarData="viewModel.calendarScheduleRangeData"
      :scheduleRangeList="viewModel.scheduleRangeDataList"
      @onAddSchedule="date => viewModel.onAddSchedule(date)"
      @getData="viewModel.getData()">
    </Calendar>
    <template v-slot:popup>
      <ScheduleRangePopup
        v-if="viewModel.isSchedulePopup"
        :isSchedulePopupModify="viewModel.isSchedulePopupModify"
        :scheduleData="viewModel.scheduleItemModel"
        @onClickClose="viewModel.onClickCloseSchedulePopup()"
        @onClickComplete="viewModel.onClickCompleteSchedulePopup()"
        @onClickDelete="scheduleData => viewModel.onClickDeleteSchedule(scheduleData)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Calendar from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/Calendar';
import ScheduleRangePopup from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/popup/ScheduleRangePopup';
// viewModel
import InspectionViewModel from '@/views/app/inspection/viewModel/InspectionViewModel'

export default {
  name:'inspection',
  components:{
    PageWithLayout,
    Calendar,
    ScheduleRangePopup
  },
  data(){
    return {
      viewModel: new InspectionViewModel(),
    }
  },
}
</script>